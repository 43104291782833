import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  event: null,
  changes: [],
  judges: {}
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
      state.changes = [];
    },

    saveEventSuccess(state) {
      state.isLoading = false;
      state.changes = [];
      // state.event = action.payload;
    },

    resetEvent(state) {
      state.isLoading = false;
      state.event = null;
      state.error = null;
      state.changes = [];
      state.judges = {};
    },
    
    addChange(state, action) {
      state.isLoading = false;
      state.changes.push(action.payload);
      // state.event = updateEventScore(state.event, action.payload);
    },
    
    updateJudgeMapping(state, action) {
      state.isLoading = false;
      state.judges[action.payload.eventclassid] = action.payload.judgeposition;
      // state.event = updateEventScore(state.event, action.payload);
    },
    
    updateEvent(state, action) {
      state.isLoading = false;
      state.event = action.payload;
      // state.event = updateEventScore(state.event, action.payload);
    },
    
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getEventSuccess,
  resetEvent,
  addChange,
  updateEvent,
  updateJudgeMapping,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadEvent(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/admin/score/id/${id}`);
      // console.debug(response.data);
      dispatch(slice.actions.getEventSuccess(response.data.record ? response.data.record : null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveChanges(event, changes) {
  return async (dispatch) => {
    console.log("DISPATCH Update")
    dispatch(slice.actions.startLoading());
    try {
      console.log("Calling post", changes);
      const response = await axios.post(`/admin/score/id/${event.id}`, changes);
      if(response.error) {
        slice.actions.hasError(response.message);
      } else {
        dispatch(slice.actions.saveEventSuccess())
      }
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export const getEvent = state => state.event;
