import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// auth
// import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

export default function Login() {
  // const { method } = useAuthContext();

  return (
    <LoginLayout
      title="Welcome to Eventing Ireland's Scoring Application"

    >
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to Eventing Ireland&apos;s Scoring Application</Typography>      

      </Stack>

      <AuthLoginForm />
    </LoginLayout>
  );
}
