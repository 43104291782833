// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  euro: icon('ic_euro'),
  pound: icon('ic_pound'),
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  horse: icon('ic_horse'),
  member: icon('ic_member'),
  entry: icon('ic_awardoutline'),
  settings: icon('ic_settings'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'summary', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'events', path: PATH_DASHBOARD.general.events, icon: ICONS.calendar },
      { title: 'my entries', path: PATH_DASHBOARD.general.myentries, icon: ICONS.entry },
      { title: 'shop', path: PATH_DASHBOARD.general.shop, icon: ICONS.cart  },
      { title: 'payments', path: PATH_DASHBOARD.general.payments, icon: ICONS.banking },
    ],
  },

  
  // Membership
  {
    subheader: 'membership',
    items: [
      { title: 'my horses', path: PATH_DASHBOARD.member.horses, icon: ICONS.horse },
      { title: 'memberships', path: PATH_DASHBOARD.member.memberships, icon: ICONS.member },
    ],
  },

    // Account
    {
      subheader: 'account',
      items: [
        { title: 'settings', path: PATH_DASHBOARD.general.settings, icon: ICONS.settings },
        { title: 'volunteering', path: PATH_DASHBOARD.general.volunteering, icon: ICONS.user },
      ],
    },
  
];

export default navConfig;
