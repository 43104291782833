// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  accountCreated: path(ROOTS_AUTH, '/account-created'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  discipline: {
    sj: path(ROOTS_DASHBOARD, '/selectlevel/sj'),
    dr: path(ROOTS_DASHBOARD, '/selectlevel/dr'),
    drs: path(ROOTS_DASHBOARD, '/selectclass/drs'),
    xc: path(ROOTS_DASHBOARD, '/selectlevel/xc'),
    all: path(ROOTS_DASHBOARD, '/selectclass/all'),
  },
  selectclassinlevel: {
    sj: (id) => path(ROOTS_DASHBOARD, `/selectclass/sj/?level=${id}`),
    dr: (id) => path(ROOTS_DASHBOARD, `/selectclass/dr/?level=${id}`),
    xc: (id) => path(ROOTS_DASHBOARD, `/selectclass/xc/?level=${id}`),
    all: (id) => path(ROOTS_DASHBOARD, `/selectclass/all/?level=${id}`),
  },
  selectentry: {
    sj: (id) => path(ROOTS_DASHBOARD, `/selectentry/sj/${id}`),
    dr: (id) => path(ROOTS_DASHBOARD, `/selectentry/dr/${id}`),
    drs: (id) => path(ROOTS_DASHBOARD, `/selectentry/drs/${id}`),
    xc: (id) => path(ROOTS_DASHBOARD, `/selectentry/xc/${id}`),
    all: (id) => path(ROOTS_DASHBOARD, `/selectentry/all/${id}`),
  },
  setupclass: {
    sj: (id) => path(ROOTS_DASHBOARD, `/setupclass/sj/${id}`),
    dr: (id) => path(ROOTS_DASHBOARD, `/setupclass/dr/${id}`),
    drs: (id) => path(ROOTS_DASHBOARD, `/setupclass/drs/${id}`),
    xc: (id) => path(ROOTS_DASHBOARD, `/setupclass/xc/${id}`),
    all: (id) => path(ROOTS_DASHBOARD, `/sesetupclass/all/${id}`),
  },
  setuplevel: {
    sj: (id) => path(ROOTS_DASHBOARD, `/setuplevel/sj/${id}`),
    dr: (id) => path(ROOTS_DASHBOARD, `/setuplevel/dr/${id}`),
    xc: (id) => path(ROOTS_DASHBOARD, `/setuplevel/xc/${id}`),
    all: (id) => path(ROOTS_DASHBOARD, `/sesetuplevel/all/${id}`),
  },
  entry: {
    sj: (id, ecid) => path(ROOTS_DASHBOARD, `/class/${ecid}/entry/${id}/sj`),
    dr: (id, ecid) => path(ROOTS_DASHBOARD, `/class/${ecid}/entry/${id}/dr`),
    drs: (id, ecid) => path(ROOTS_DASHBOARD, `/class/${ecid}/entry/${id}/drs`),
    xc: (id, ecid) => path(ROOTS_DASHBOARD, `/class/${ecid}/entry/${id}/xc`),
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    myentries: path(ROOTS_DASHBOARD, '/myentries'),
    entry: (id) => path(ROOTS_DASHBOARD, `/entry/${id}`),
    events: path(ROOTS_DASHBOARD, '/events'),
    loadevent: path(ROOTS_DASHBOARD, '/loadevent'),
    event: (id) => path(ROOTS_DASHBOARD, `/events/${id}`),
    class: (eventid, classid) => path(ROOTS_DASHBOARD, `/events/class/${eventid}/${classid}`),
    cart: (cartname) => path(ROOTS_DASHBOARD, `/cart/${cartname}`),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    payments: path(ROOTS_DASHBOARD, '/payments'),
    payment: (id) => path(ROOTS_DASHBOARD, `/payments/${id}`),
    shop: path(ROOTS_DASHBOARD, '/shop'),
    shopitem: (id) => path(ROOTS_DASHBOARD, `/shop/${id}`),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    volunteering: path(ROOTS_DASHBOARD, '/volunteering'),
  },
  member: {
    addhorse: path(ROOTS_DASHBOARD, '/horse/add'),
    addmember: path(ROOTS_DASHBOARD, '/member/add'),
    horses: path(ROOTS_DASHBOARD, '/horses'),
    horse: (id) => path(ROOTS_DASHBOARD, `/horses/${id}`),
    memberships: path(ROOTS_DASHBOARD, '/memberships'),
    membership: (id) => path(ROOTS_DASHBOARD, `/memberships/${id}`),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  testing: {
    root: path(ROOTS_DASHBOARD, '/testing'),
    sink: path(ROOTS_DASHBOARD, '/testing/sink'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
