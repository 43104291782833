import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import eventReducer from './slices/event';
import eventsReducer from './slices/events';
import settingsReducer from './slices/settings';

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
  blacklist: ['events']
};

export const eventsPersistConfig = {
  key: 'events',
  storage,
  keyPrefix: 'redux-',
};

export const eventPersistConfig = {
  key: 'event',
  storage,
  keyPrefix: 'redux-',
};

export const settingsPersistConfig = {
  key: 'settings',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  events: persistReducer(eventsPersistConfig, eventsReducer),
  event: persistReducer(eventPersistConfig, eventReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
});

export default rootReducer;
