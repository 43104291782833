import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  LoginPage,
  DashboardPage,
  LoadEventPage,
  DrEventClassPage,
  DrsEventClassPage,
  DrSelectLevelPage,
  XcEventClassPage,
  AllEventClassPage,
  SjSelectLevelPage,
  SjEventClassPage,
  SjSelectEntryPage,
  XcSelectLevelPage,
  DrSelectEntryPage,
  DrsSelectEntryPage,
  XcSelectEntryPage,
  AllSelectEntryPage,
  DressageScoringPage,
  DressageScribeScoringPage,
  ShowJumpingScoringPage,
  Page500,
  Page403,
  Page404,
  MaintenancePage,
  SetupEventClassDetails,
  SetupLevelDetails,
  CrossCountryScoringPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      // path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'loadevent', element: <LoadEventPage /> },
        { path: 'selectlevel/sj', element: <SjSelectLevelPage /> },
        { path: 'selectlevel/xc', element: <XcSelectLevelPage /> },
        { path: 'selectlevel/dr', element: <DrSelectLevelPage /> },
        { path: 'selectclass/drs', element: <DrsEventClassPage/> },
        { path: 'selectclass/sj', element: <SjEventClassPage /> },
        { path: 'selectclass/dr', element: <DrEventClassPage /> },
        { path: 'selectclass/xc', element: <XcEventClassPage /> },
        { path: 'selectclass/all', element: <AllEventClassPage /> },
        { path: 'setupclass/all/:id', element: <SetupEventClassDetails /> },
        { path: 'setupclass/xc/:id', element: <SetupEventClassDetails /> },
        { path: 'setupclass/dr/:id', element: <SetupEventClassDetails /> },
        { path: 'setupclass/drs/:id', element: <SetupEventClassDetails /> },
        { path: 'setupclass/sj/:id', element: <SetupEventClassDetails /> },

        { path: 'setuplevel/all/:id', element: <SetupLevelDetails /> },
        { path: 'setuplevel/xc/:id', element: <SetupLevelDetails /> },
        { path: 'setuplevel/dr/:id', element: <SetupLevelDetails /> },
        { path: 'setuplevel/sj/:id', element: <SetupLevelDetails /> },

        { path: 'selectentry/sj/:id', element: <SjSelectEntryPage /> },
        { path: 'selectentry/dr/:id', element: <DrSelectEntryPage /> },
        { path: 'selectentry/drs/:id', element: <DrsSelectEntryPage /> },
        { path: 'selectentry/xc/:id', element: <XcSelectEntryPage /> },
        { path: 'selectentry/all/:id', element: <AllSelectEntryPage /> },
        { path: 'class/:ecid/entry/:id/sj', element: <ShowJumpingScoringPage /> },
        { path: 'class/:ecid/entry/:id/xc', element: <CrossCountryScoringPage /> },
        { path: 'class/:ecid/entry/:id/dr', element: <DressageScoringPage /> },
        { path: 'class/:ecid/entry/:id/drs', element: <DressageScribeScoringPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
