import PropTypes from 'prop-types';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Stack, AppBar, Toolbar, Typography, Chip,
  Card, CircularProgress, CardContent, CardHeader, Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSelector } from 'react-redux'
// import { store, useDispatch } from '../../redux/store';
import { saveChanges, loadEvent } from '../../../redux/slices/event';

// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import { useSettingsContext } from '../../../components/settings';
//
import { DialogAnimate } from '../../../components/animate';

import AccountPopover from './AccountPopover';
import { dispatch } from '../../../redux/store';
import { useOnlineStatus } from '../../../hooks/useOnlineStatus';


// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();
  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const { event, changes, isLoading } = useSelector((state) => state.event);
  const isOnline = useOnlineStatus();




  const update = () => {
    // dispatch(saveChanges());
    dispatch(saveChanges(event, changes));

  }

  const reload = () => {
    dispatch(loadEvent(event.id));
  }



  const renderContent = (
    <>
      <Logo sx={{ mr: 2.5 }} />

      {event && <Typography variant="body2" color='text.primary'>Scoring: {event.name}</Typography>}
      {!event && <Typography variant="body2" color='text.primary'>EI Scoring App</Typography>}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* {!isOnline && <Alert sx={{ }} severity="error">Currently Offline</Alert>} */}
        {isOnline && event && changes.length === 0 && <LoadingButton loading={isLoading} variant="soft" onClick={reload}>Reload</LoadingButton>}
        {isOnline && event && changes && changes.length > 0 && <LoadingButton loading={isLoading} variant="soft" onClick={update}>Update ({changes.length})</LoadingButton>}
        {!isOnline && event && changes && changes.length > 0 && <Typography variant="caption" sx={{ color: 'text.secondary' }}>Changes ({changes.length})</Typography>}
        {!isOnline && <Chip label="Offline" color="error" />}

        <AccountPopover online={isOnline} />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE / 2,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100%px)`,
          height: HEADER.H_DASHBOARD_DESKTOP / 2,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET / 2,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET / 2,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 0.5,
          px: { lg: 5 },
        }}
        variant="dense"
      >

        {renderContent}
      </Toolbar>
      <DialogAnimate open={isLoading}>
        <Card >
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Fetching Event Data
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={10}><Typography variant="body2" color="text.secondary">
                Connecting to server and downloading the complete set of results data for this event, please wait...
              </Typography></Grid>
              <Grid item xs={2}><CircularProgress /></Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogAnimate>
    </AppBar>
  );
}
