import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------


// AUTH
export const LoginPage = Loadable(lazy(() => import('../scoring/pages/auth/LoginPage')));
export const DashboardPage = Loadable(lazy(() => import('../scoring/pages/DashboardPage')));

export const LoadEventPage = Loadable(
  lazy(() => import('../scoring/pages/LoadEventPage'))
);

export const SjEventClassPage = Loadable(
  lazy(() => import('../scoring/pages/SjEventClassPage'))
);

export const DrEventClassPage = Loadable(
  lazy(() => import('../scoring/pages/DrEventClassPage'))
);

export const DrsEventClassPage = Loadable(
  lazy(() => import('../scoring/pages/DrsEventClassPage'))
);

export const XcEventClassPage = Loadable(
  lazy(() => import('../scoring/pages/XcEventClassPage'))
);

export const AllEventClassPage = Loadable(
  lazy(() => import('../scoring/pages/AllEventClassPage'))
);

export const AllSelectEntryPage = Loadable(
  lazy(() => import('../scoring/pages/AllSelectEntryPage'))
);

export const SjSelectEntryPage = Loadable(
  lazy(() => import('../scoring/pages/SjSelectEntryPage'))
);

export const DrSelectLevelPage = Loadable(
  lazy(() => import('../scoring/pages/DrSelectLevelPage'))
);

export const SjSelectLevelPage = Loadable(
  lazy(() => import('../scoring/pages/SjSelectLevelPage'))
);

export const XcSelectLevelPage = Loadable(
  lazy(() => import('../scoring/pages/XcSelectLevelPage'))
);

export const DrSelectEntryPage = Loadable(
  lazy(() => import('../scoring/pages/DrSelectEntryPage'))
);

export const DrsSelectEntryPage = Loadable(
  lazy(() => import('../scoring/pages/DrsSelectEntryPage'))
);

export const XcSelectEntryPage = Loadable(
  lazy(() => import('../scoring/pages/XcSelectEntryPage'))
);

export const CrossCountryScoringPage = Loadable(
  lazy(() => import('../scoring/pages/CrossCountryScoringPage'))
);

export const ShowJumpingScoringPage = Loadable(
  lazy(() => import('../scoring/pages/ShowJumpingScoringPage'))
);

export const DressageScoringPage = Loadable(
  lazy(() => import('../scoring/pages/DressageScoringPage'))
);

export const DressageScribeScoringPage = Loadable(
  lazy(() => import('../scoring/pages/DressageScribeScoringPage'))
);

export const SetupEventClassDetails = Loadable(
  lazy(() => import('../scoring/pages/SetupEventClassDetails'))
);

export const SetupLevelDetails = Loadable(
  lazy(() => import('../scoring/pages/SetupLevelDetails'))
);


// // MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));